import React from 'react';


const Section = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <div className="subtitle mb-3">AI Novels </div>
                    <h2 className="mb20">Easy to use AI Novel generation</h2>
                </div>

                <div className="col-lg-6"></div>

                <div className="col-lg-3 col-md-6 mb-sm-20" >
                    <div>
                        <img src="./img/icons/1.png" className="mb20" alt=""/>
                        <h4>Super Quick Setup</h4>
                        <p>You can start using novelfy instantly,you just have to sign up and answer a few basic questions. </p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/2.png" classNamez="mb20" alt=""/>
                        <h4>Premium speeds</h4>
                        <p>We deliver fast data response times, which allows you to get your novels out faster.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/3.png" className="mb20" alt=""/>
                        <h4>Realistic novel writing</h4>
                        <p>Unlike other services, when you use our service you get a novel that sounds like a real author responded.</p>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6 mb-sm-20">
                    <div>
                        <img src="./img/icons/4.png" className="mb20" alt=""/>
                        <h4>Novel tracking</h4>
                        <p>Premium users will be able to see their past generated novels at any time.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section;