import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom"; 
import Navbar from '../layout/Navbar';
import Preloader from '../layout/preloader';
import Footer from '../section-pages/footer';
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const image1 ="./img/background/space.webp"; 

const GlobalStyles = createGlobalStyle`
  .react-parallax-bgimage {
    transform: translate3d(-50%, 0, 0px) !important;
  }
`;

export default function Home() {
    const google = () => {
        window.open("https://novel-backend.onrender.com/login/google ", "_self");
    }
    const facebook = () => {
        window.open("https://novel-backend.onrender.com/login/facebook", "_self");
    }
    const twitter = () => {
        window.open("https://novel-backend.onrender.com/login/twitter", "_self");
    }
    const discord = () => {
        window.open("https://novel-backend.onrender.com/login/discord", "_self");
    }
  useEffect(() => {
      if (typeof window !== 'undefined') {
          const loader = document.getElementById('mainpreloader');
          if (loader)
          setTimeout(() => {
            loader.classList.add("fadeOut");
            loader.style.display = 'none';
          }, 600)
      }
    }, []);
  return (
    <>
    {/* HEAD */}
    <Helmet>
      <link rel="icon" href="./img/icon.png" />
      <title>Novelfy - login</title>
    </Helmet>

    <GlobalStyles/>

    {/* LOADER */}
    <div id='mainpreloader'>
      <Preloader/>
    </div>

    {/* MENU */}
    <div className="home dark-scheme">
      <header id="header-wrap">
         <Navbar />
      </header>

      {/* section */}
      <Parallax className="" bgImage={image1} strength={5}>  
        <div className="de-gradient-edge-top"></div>
        <div className="de-gradient-edge-bottom"></div>
        <section className="no-bg">
          <div className="container z-9">
            <div className="row align-items-center">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="p-5 rounded-10 shadow-soft bg-dark-1" id="login">
                                    <form name="contactForm" id='contact_form' className="form-border">
                                        <h4>Don&apos;t have an account? Register now.</h4>
                                        <p>Welcome to Novelfy. We&apos;re excited to have you on board. By creating an account with us, you&apos;ll gain access to a range of benefits and convenient features that will enhance your car rental experience.</p>
                                        

                                            <div className="col-lg-6 offset-lg-3">
                                                <div className="title-line">Or&nbsp;sign&nbsp;up&nbsp;with</div>
                                                <div className="row g-2">
                                                    <div className="col-lg-6">
                                                        <Link className="btn-sc btn-fullwidth mb10" to="" onClick={google}><img src="./img/svg/google_icon.svg" alt=""/>Google</Link>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Link className="btn-sc btn-fullwidth mb10" to="/" onClick={facebook}><img src="./img/svg/facebook_icon.svg" alt=""/>Facebook</Link>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Link className="btn-sc btn-fullwidth mb10" to="/" onClick={twitter}><img src="./img/svg/twitter_icon.svg" alt=""/>X</Link>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <Link className="btn-sc btn-fullwidth mb10" to="/" onClick={discord}><img src="./img/svg/discord_icon.svg" alt=""/>Discord</Link>
                                                    </div>
                                                </div>
                                            </div>

                                  
                                    </form>
                                </div>
                            </div>
                        </div>
          </div>
        </section>
      </Parallax>

      {/* footer */}
      <Footer/>


    </div>
    <ScrollToTopBtn />
    </>
  )
}
