import React from 'react';
import { Link } from 'react-router-dom';

const collection = () => {
    return(
        <div className="container">
            <div className="row">
              <div className="col-md-6">
                  <div className="subtitle mb20">Start for free!</div>
                  <h2 className="wow fadeInUp">We have all types of novels!</h2>
                  <div className="spacer-20"></div>
              </div>
              <div className="col-lg-6 text-lg-end">
                  <Link className="btn-main mb-sm-30" to="/login">Get started</Link>
              </div>
            </div>
            <div className="row g-4 sequence">

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                          $0/m
                        </div>
                        <div className="d-text">
                            <h4>Science Fiction Novels</h4>
                            <p className="d-price">  <span className="price"></span></p>
                            <Link className="btn-main btn-fullwidth" to="/">Get started</Link>
                        </div>
                    </div>
                    <img src="./img/covers/Frame 2.svg" className="img-fluid " alt=""/>
                </div>
            </div>

            <div className="col-lg-3 col-md-6 gallery-item">
                <div className="de-item wow">
                    <div className="d-overlay">
                        <div className="d-label">
                        $0/m
                        </div>
                        <div className="d-text">
                            <h4>Thriler  Novels</h4>
                            <p className="d-price"> <span className="price"></span></p>
                            <Link className="btn-main btn-fullwidth" to="/">Get started</Link>
                        </div>
                    </div>
                    <img src="./img/covers/Frame 8.svg" className="img-fluid " alt=""/>
                </div>
            </div>

          

            </div>

        </div>
    );
}

export default collection;