import React from 'react';
import { Link } from "react-router-dom";

const Sectioncol = () => {
    return(
        <div className="container">
            <div className="row">

                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Servers Near You</h3>
                        <p>Our propeitary technology automatically pulls from a cloud server near you to increase your response time.</p>
                        <Link className="btn-line lg-w-50" to="/">Start your novel!</Link>
                </div>
                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Human voice</h3>
                        <p>We've leverage today's technology to invent a human writing tool powered by Artificial intelligence to take your novels to the next level!</p>
                        <Link className="btn-line lg-w-50" to="/">Get started</Link>
                </div>
                <div className="col-lg-4 col-md-6 mb-sm-30">
                    <h3>Still not convinced</h3>
                        <p>Our limited trial allows you to use our software with no commitment, at it's lowest level.</p>
                        <Link className="btn-line lg-w-50" to="/">sign up</Link>
                </div>
            </div>
        </div>
    );
}

export default Sectioncol;