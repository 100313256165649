import React, { useState, useEffect } from 'react';

const ClaudeApiRequest = () => {
  const [data, setData] = useState(null);
  const [genre, setGenre] = useState('');
  const [characterName, setCharacterName] = useState('');
  const [plot, setPlot] = useState('');

  const fetchData = async () => {
    const apiKey = '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';
    const prompt = `Genre: ${genre}\nCharacter Name: ${characterName}\nPlot: ${plot}`;
    const response = await fetch(`https://api.claude.ai/v1/data?api_key=${apiKey}&prompt=${encodeURIComponent(prompt)}`);
    const jsonData = await response.json();
    setData(jsonData);
  };

  return (
    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '10px', padding: '20px', boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)' }}>
      <label htmlFor="genre">Genre:</label>
      <input
        type="text"
        id="genre"
        value={genre}
        onChange={(event) => setGenre(event.target.value.slice(0, 100))}
        style={{ outline: '2px solid white', color: 'cyan' }}
      />
      <label htmlFor="characterName">Character Name:</label>
      <input
        type="text"
        id="characterName"
        value={characterName}
        onChange={(event) => setCharacterName(event.target.value.slice(0, 100))}
        style={{ outline: '2px solid white', color: 'cyan' }}
      />
      <label htmlFor="plot">Plot:</label>
      <input
        type="text"
        id="plot"
        value={plot}
        onChange={(event) => setPlot(event.target.value.slice(0, 100))}
        style={{ outline: '2px solid white', color: 'cyan' }}
      />
      <button type="button" onClick={fetchData} style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px', padding: '10px 20px', cursor: 'pointer', color: 'cyan' }}>
        Send API Request
      </button>
      {data && <pre style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: '5px', padding: '10px', marginTop: '20px', color: 'cyan' }}>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
};

export default ClaudeApiRequest;