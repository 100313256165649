import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { No } from 'react-flags-select';


const Slider = () => {
  return (
    <Swiper className="mainslider"
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={50}
      slidesPerView={1}
      loop={true}
      navigation
      autoplay={{ delay: 4000 }} 
      pagination={{
        clickable: false,
        renderBullet: function (index, className) {
          return `<span class="${className}">${index + 1} <span className="swiper-pagination-current">/ 4</span></span>`;
        },
      }}
      >
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/Frame 13.svg")`,
                                              backgroundPosition: 'center',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                              height: '100vh',
                                              width: '100vw',
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-8 mb-sm-30">
                            <div Link className="subtitle blink mb-4" to="../login">Get Started</div>
                            <h1 className="slider-title text-uppercase mb-1">NovelFY.AI</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="slider-text"></p>
                            <div className="sw-price wp">
                                <div className="d-starting">
                                  
                                </div>
                                <div className="d-price">
                                    <span className="d-cur"></span>
                                    <span className="d-val"></span>
                                    <span className="d-period"></span>
                                </div>
                            </div>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="../register">sign up now!</Link>
                        </div>
                    </div>
                    <div>

                        
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/Frame 14.svg")`,
                                              backgroundPosition: 'center',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                              height: '100vh',
                                              width: '100vw',
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-8 mb-sm-30">
                            <div className="subtitle blink mb-4">Novel Writing</div>
                            <h1 className="slider-title text-uppercase mb-1">NovelFY.AI</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="slider-text"></p>
                            <div className="sw-price ">
                            <div className="d-starting">
                                   
                                </div>
                                <div className="d-price">
                                    <span className="d-cur"></span>
                                    <span className="d-val"></span>
                                    <span className="d-period"></span>
                                </div>
                            </div>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="../register">sign up now!</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/Frame 12.svg")`,
                                              backgroundPosition: 'center',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                              height: '100vh',
                                              width: '100vw',
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-8 mb-sm-30">
                            <div className="subtitle blink mb-4">AI NOVELS</div>
                            <h1 className="slider-title text-uppercase mb-1">NovelFY.AI</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="slider-text"></p>
                            <div className="sw-price ">
                            <div className="d-starting">
                                   
                                </div>
                                <div className="d-price">
                                    <span className="d-cur"></span>
                                    <span className="d-val"></span>
                                    <span className="d-period"></span>
                                </div>
                            </div>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="../register">sign up now!</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner" style={{
                                              backgroundImage: `url("./img/slider/Frame 7.svg")`,
                                              backgroundPosition: 'center',
                                              backgroundSize: 'cover',
                                              backgroundRepeat: 'no-repeat',
                                              height: '100vh',
                                              width: '100vw',
                                            }}>
            <div className="sw-caption">
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        <div className="col-lg-8 mb-sm-30">
                            <div className="subtitle blink mb-4">AI novels</div>
                            <h1 className="slider-title text-uppercase mb-1">NovelFY.AI</h1>
                        </div>
                        <div className="col-lg-6">
                            <p className="slider-text"></p>
                            <div className="sw-price ">
                            <div className="d-starting">
                                   
                                </div>
                                <div className="d-price">
                                    <span className="d-cur"></span>
                                    <span className="d-val"></span>
                                    <span className="d-period"></span>
                                </div>
                            </div>
                            <div className="spacer-10"></div>
                            <Link className="btn-main mb10" to="../register">sign up now!</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sw-overlay"></div>
        </div>         
      </SwiperSlide>
  
    </Swiper>
  );
};
export default Slider;