import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

const NavLink = (props) => {
  return <Link {...props} />;
};

const Navbar = () => {
  const useDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const closeDropdown = () => {
      setIsOpen(false);
    };

    const ref = useRef(null);
    useOnclickOutside(() => {
      closeDropdown();
    }, ref);

    return {
      isOpen,
      toggleDropdown,
      closeDropdown,
      ref,
    };
  };

const { isOpen: openMenu, toggleDropdown: handleBtnClick, closeDropdown: closeMenu, ref } = useDropdown();
const { isOpen: openMenu1, toggleDropdown: handleBtnClick1, closeDropdown: closeMenu1, ref: ref1 } = useDropdown();
const { isOpen: openMenu2, toggleDropdown: handleBtnClick2, closeDropdown: closeMenu2, ref: ref2 } = useDropdown();
const { isOpen: openMenu3, toggleDropdown: handleBtnClick3, closeDropdown: closeMenu3, ref: ref3 } = useDropdown();
const { isOpen: openMenu4, toggleDropdown: handleBtnClick4, closeDropdown: closeMenu4, ref: ref4 } = useDropdown();

  const [showmenu, setBtnIcon] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header-wrap");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;

    const scrollCallBack = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    };

    window.addEventListener("scroll", scrollCallBack);

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <nav className="navbar transition">
        <div className="container">

          {/********* Logo *********/}
          <NavLink  className="navbar-brand" to="/">
            <img src="./img/logo.png" className="img-fluid d-md-block d-none imginit" alt="logo"/>
            <img src="./img/logo-mobile.png" className="img-fluid d-md-none d-sms-none imginit" alt="logo"/>
          </NavLink>
          {/********* Logo *********/}

              {/********* Mobile Menu *********/}
              <div className="mobile">
                {showmenu && 
                <div className='menu'>

                  <div>
                    <div ref={ref}>
                      <div className="dropdown-custom dropdown-toggle btn" 
                        onClick={() => {
                                        handleBtnClick();
                                        closeMenu1();
                                        closeMenu2();
                                        closeMenu3();
                                        closeMenu4();
                                      }}>
                        Home
                      </div>
                      {openMenu && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu}>
                            <NavLink to="/" onClick={() => setBtnIcon(!showmenu)}>Home</NavLink>
               
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div>
                    <div ref={ref1}>
                      <div className="dropdown-custom dropdown-toggle btn" 
                        onClick={() => {
                                        handleBtnClick1();
                                        closeMenu();
                                        closeMenu2();
                                        closeMenu3();
                                        closeMenu4();
                                      }}>
                        Costs
                      </div>
                      {openMenu1 && (
                        <div className='item-dropdown'>
                          <div className="dropdown" onClick={closeMenu1}>
                            <NavLink to="/pricing" onClick={() => setBtnIcon(!showmenu)}>Pricing</NavLink>
                            
                      
                        </div>
                        </div>
                      )}
                    </div>
                  </div>

                  

                    <div>
                      <div ref={ref4}>
                        <div className="dropdown-custom dropdown-toggle btn" 
                          onClick={() => {
                                        handleBtnClick4();
                                        closeMenu1();
                                        closeMenu2();
                                        closeMenu3();
                                        closeMenu();
                                      }}>
                          Login
                        </div>
                        {openMenu4 && (
                          <div className='item-dropdown'>
                            <div className="dropdown" onClick={closeMenu4}>
                              <NavLink to="/login" onClick={() => setBtnIcon(!showmenu)}>Login</NavLink>
                              <NavLink to="/aistoryteller" onClick={() => setBtnIcon(!showmenu)}>TEST</NavLink>
                               </div>
                          </div>
                        )}
                      </div>
                    </div>

                </div>
                }
          </div>
          {/********* Mobile Menu *********/}

          {/********* Dekstop Menu *********/}
          <div className="dekstop">
          <div className='menu'>

            <div>
                <div ref={ref}>
                  <div className="dropdown-custom dropdown-toggle btn" 
                     onMouseEnter={handleBtnClick} onMouseLeave={closeMenu}>
                    Home
                    {openMenu && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu}>
                        <NavLink to="/">Homepage</NavLink>
              
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
            </div>

            <div>
                <div ref={ref1}>
                  <div className="dropdown-custom dropdown-toggle btn" 
                     onMouseEnter={handleBtnClick1} onMouseLeave={closeMenu1}>
                    Pricing
                    {openMenu1 && (
                    <div className='item-dropdown'>
                      <div className="dropdown" onClick={closeMenu1}>
                        <NavLink to="/pricing">Pricing</NavLink>
                   
                      </div>
                    </div>
                  )}
                  </div>
                  
                </div>
            </div>

          
          

              <div>
                  <div ref={ref4}>
                    <div className="dropdown-custom dropdown-toggle btn" 
                       onMouseEnter={handleBtnClick4} onMouseLeave={closeMenu4}>
                      Login
                      {openMenu4 && (
                      <div className='item-dropdown'>
                        <div className="dropdown" onClick={closeMenu4}>
                          <NavLink to="/login">Login</NavLink>
                          <NavLink to="/aistoryteller">TEST</NavLink>
                       
                        </div>
                      </div>
                    )}
                    </div>
                    
                  </div>
              </div>

            </div>
          </div>
          {/********* Dekstop Menu *********/}

          {/********* Side Button *********/}
          <div className="menu_side_area">
              <NavLink to="/register" className="btn-line" id="btn-line">Get started</NavLink>
              {/********* Burger Button *********/}
              <button className="burgermenu" type="button" 
                onClick={() => {
                  setBtnIcon(!showmenu);
                  closeMenu1();
                  closeMenu2();
                  closeMenu3();
                  closeMenu4();
                }}>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </button>
              {/********* Burger Button *********/}
          </div>
          {/********* Side Button *********/}

          

        </div>
      </nav>
    )
}

export default Navbar;