import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { Link } from "react-router-dom"; 

const Server = () => {
    // select GB
    const [selected, setSelected] = useState('GB'); // Change 'GB' to the desired country code
    const handleSelect = (countryCode) => {
    setSelected(countryCode);
    };

    // select GB
    const [selected1, setSelected1] = useState('GB'); // Change 'GB' to the desired country code
    const handleSelect1 = (countryCode) => {
    setSelected1(countryCode);
    };

    // select GB
    const [selected2, setSelected2] = useState('GB'); // Change 'GB' to the desired country code
    const handleSelect2 = (countryCode) => {
    setSelected2(countryCode);
    };

    // select GB
    const [selected3, setSelected3] = useState('GB'); // Change 'GB' to the desired country code
    const handleSelect3 = (countryCode) => {
    setSelected3(countryCode);
    };

    //switch
    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
    setIsChecked((prevState) => !prevState);
    };
    const switchClassName = isChecked ? 'switch checked' : 'switch';
    const otherElementClassName = isChecked ? 'other-element active' : 'other-element';

    return(
        <>
                <div className="de-gradient-edge-top"></div>
                <div className="de-gradient-edge-bottom"></div>
                <div className="container z-9">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 text-center">                  
                            <div className="switch-set">
                                <div>Monthly Plan</div>
                                <div>
                                    <input
                                      id="sw-1"
                                      className={switchClassName}
                                      type="checkbox"
                                      checked={isChecked}
                                      onChange={handleToggle}
                                    />
                                </div>
                                <div>Yearly Plan</div>
                                <div className="spacer-20"></div>
                            </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="col-xl-3 col-md-6 mb-sm-30 fadeInRight" >
                            <div className={`de_pricing-table type-2 ${otherElementClassName}`}>
                                <div className="d-head">
                                    <h3>Free</h3>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$0.00<span>/mo</span></h4>
                                    <h4 className="opt-2">$0.00<span>/mo</span></h4>
                                    <p>Normally <s>$9.99</s></p>
                                </div>                                
                                <div className="d-location">
                                    <h4>Server Location</h4>
                                    <ReactFlagsSelect
                                        className="flags"
                                        countries={["GB", "FR", "DE", "NL", "SE", "FI", "US", "QC", "AU", "BR", "TH", "ID"]}
                                        customLabels={{ 
                                        GB: "London, England", 
                                        FR: "Paris, France", 
                                        DE: "Frankurt, Germany", 
                                        NL: "Amsterdam, Netherlands", 
                                        SE: "Stockholm, Sweden", 
                                        FI: "Helsinki, Finland", 
                                        US: "Los Angeles, USA", 
                                        QC: "Quebec, Canada",
                                        AU: "Sydney, Australia", 
                                        BR: "Sau Paulo, Brazil", 
                                        TH: "Bangkok, Thailand", 
                                        ID: "Jakarta, Indonesia"
                                        }}
                                        selected={selected}
                                        onSelect={handleSelect}
                                        searchable={false}
                                    />
                                </div>
                                <div className="spacer-5"></div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
                                        <li>Lowest Price</li>
                                        <li>character limit</li>
                                        <li>5 saved novels</li>
                                        <li>No Premium features</li>
                                    </ul>
                                </div>
                                <div className="d-action">
                                    <Link to="/" className="btn-main opt-1 w-100">Get Monthly Plan</Link>
                                    <Link to="/" className="btn-main opt-2 w-100">Get Yearly Plan</Link>
                                    <p>Auto re-news at regular rate</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-sm-30 fadeInRight" >
                            <div className={`de_pricing-table type-2 ${otherElementClassName}`}>
                                <div className="d-head">
                                    <h3>level I</h3>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$9.99<span>/mo</span></h4>
                                    <h4 className="opt-2">$69.99<span>/yr</span></h4>
                                    <p>Normally <s>$15.99</s></p>
                                </div>
                                <div className="d-location">
                                    <h4>Server Location</h4>
                                    <ReactFlagsSelect
                                        className="flags"
                                        countries={["GB", "FR", "DE", "NL", "SE", "FI", "US", "QC", "AU", "BR", "TH", "ID"]}
                                        customLabels={{ 
                                        GB: "London, England", 
                                        FR: "Paris, France", 
                                        DE: "Frankurt, Germany", 
                                        NL: "Amsterdam, Netherlands", 
                                        SE: "Stockholm, Sweden", 
                                        FI: "Helsinki, Finland", 
                                        US: "Los Angeles, USA", 
                                        QC: "Quebec, Canada",
                                        AU: "Sydney, Australia", 
                                        BR: "Sau Paulo, Brazil", 
                                        TH: "Bangkok, Thailand", 
                                        ID: "Jakarta, Indonesia"
                                        }}
                                        selected={selected1}
                                        onSelect={handleSelect1}
                                        searchable={false}
                                    />
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
                                        <li>10 novel saves</li>
                                        <li>Higher character limit</li>
                                        <li>50 daily novels</li>
                                        <li>Premium features</li>
                                    </ul>
                                </div>
                                <div className="d-action">
                                    <Link to="/" className="btn-main opt-1 w-100">Get Monthly Plan</Link>
                                    <Link to="/" className="btn-main opt-2 w-100">Get Yearly Plan</Link>
                                    <p>Auto re-news at regular rate</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-sm-30 fadeInRight">
                            <div className={`de_pricing-table type-2 rec ${otherElementClassName}`}>
                                <div className="d-recommend">Best Seller</div>
                                <div className="d-head">
                                    <h3>Level II</h3>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$19.99<span>/mo</span></h4>
                                    <h4 className="opt-2">$199.99<span>/yr</span></h4>
                                    <p>Normally <s>$35.99</s></p>
                                </div>
                                <div className="d-location">
                                    <h4>Server Location</h4>
                                    <ReactFlagsSelect
                                        className="flags"
                                        countries={["GB", "FR", "DE", "NL", "SE", "FI", "US", "QC", "AU", "BR", "TH", "ID"]}
                                        customLabels={{ 
                                        GB: "London, England", 
                                        FR: "Paris, France", 
                                        DE: "Frankurt, Germany", 
                                        NL: "Amsterdam, Netherlands", 
                                        SE: "Stockholm, Sweden", 
                                        FI: "Helsinki, Finland", 
                                        US: "Los Angeles, USA", 
                                        QC: "Quebec, Canada",
                                        AU: "Sydney, Australia", 
                                        BR: "Sau Paulo, Brazil", 
                                        TH: "Bangkok, Thailand", 
                                        ID: "Jakarta, Indonesia"
                                        }}
                                        selected={selected2}
                                        onSelect={handleSelect2}
                                        searchable={false}
                                    />
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
                                        <li>15 novel saves</li>
                                        <li>Higher character limit</li>
                                        <li>60 daily novels</li>
                                        <li>More human like writing</li>
                                        <li>Faster response time</li>
                                        <li>Premium features</li>
                                        <li>Beta access to new features</li>
                                    </ul>
                                </div>                                
                                <div className="d-action">
                                    <Link to="/" className="btn-main opt-1 w-100">Get Monthly Plan</Link>
                                    <Link to="/" className="btn-main opt-2 w-100">Get Yearly Plan</Link>
                                    <p>Auto re-news at regular rate</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-sm-30 fadeInRight" >
                            <div className={`de_pricing-table type-2 rec ${otherElementClassName}`}>
                                <div className="d-head">
                                    <h3>Novelist</h3>
                                </div>
                                <div className="d-price">
                                    <h4 className="opt-1">$29.99<span>/mo</span></h4>
                                    <h4 className="opt-2">$269.99<span>/yr</span></h4>
                                    <p>Normally <s>$349.99</s></p>
                                </div>
                                <div className="d-location">
                                    <h4>Server Location</h4>
                                    <ReactFlagsSelect
                                        className="flags"
                                        countries={["GB", "FR", "DE", "NL", "SE", "FI", "US", "QC", "AU", "BR", "TH", "ID"]}
                                        customLabels={{ 
                                        GB: "London, England", 
                                        FR: "Paris, France", 
                                        DE: "Frankurt, Germany", 
                                        NL: "Amsterdam, Netherlands", 
                                        SE: "Stockholm, Sweden", 
                                        FI: "Helsinki, Finland", 
                                        US: "Los Angeles, USA", 
                                        QC: "Quebec, Canada",
                                        AU: "Sydney, Australia", 
                                        BR: "Sau Paulo, Brazil", 
                                        TH: "Bangkok, Thailand", 
                                        ID: "Jakarta, Indonesia"
                                        }}
                                        selected={selected3}
                                        onSelect={handleSelect3}
                                        searchable={false}
                                    />
                                </div>
                                <div className="d-group">
                                    <h4>Top Features</h4>
                                    <ul className="d-list">
                                    <li>50 novel saves</li>
                                        <li>No character limit</li>
                                        <li>300 daily novels</li>
                                        <li>More human like writing</li>
                                        <li>Faster response time</li>
                                        <li>Premium features</li>
                                        <li>Beta access to new features</li>
                                        <li>24/7 Support</li>
                                        <li>Feature development</li>
                                    </ul>
                                </div>
                                <div className="d-action">
                                    <Link to="/" className="btn-main opt-1 w-100">Get Monthly Plan</Link>
                                    <Link to="/" className="btn-main opt-2 w-100">Get Yearly Plan</Link>
                                    <p>Auto re-news at regular rate</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
        </>
    );
}

export default Server;