import React from 'react';

const help = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-lg-6">                            
                    <div className="subtitle  mb-3">Do you have</div>
                    <h2 className=" mb20">Any questions?</h2>
                </div>

                <div className="clearfix"></div>

                <div className="col-lg-6">

                    <div className="accordion accordion-flush" id="accflush">

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c1" aria-expanded="false" aria-controls="flush-c1">
                            What is novelfy?
                          </button>
                        </h3>
                        <div id="flush-c1" className="accordion-collapse collapse" aria-labelledby="flush-h1" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>NovelFy is the leading artificial intelligence platform for novelists. Our tecnology allows you to create novels in minutes.</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h2">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c2" aria-expanded="false" aria-controls="flush-c2">
                            Why do I need novelfy?
                          </button>
                        </h3>
                        <div id="flush-c2" className="accordion-collapse collapse" aria-labelledby="flush-h2" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>Writing your own novel can take months or even years, with novelfy we have leveraged AI to capture the human element of authors, saving you months of work!</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h3">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c3" aria-expanded="false" aria-controls="flush-c3">
                            How much does novelfy cost?
                          </button>
                        </h3>
                        <div id="flush-c3" className="accordion-collapse collapse" aria-labelledby="flush-h3" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>Novelfy is a fremium service, which means you can get started for free, and paid subscribers get access to better tools and new features!</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h4">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c4" aria-expanded="false" aria-controls="flush-c4">
                            What types of novels can I generate?
                          </button>
                        </h3>
                        <div id="flush-c4" className="accordion-collapse collapse" aria-labelledby="flush-h4" data-bs-parent="#accflush">
                          <div className="accordion-body">
                              <p>You can host various types of novels, including romance novels, adventure novels, thrillers, science fiction novels, murder mystery novels and more. The type of novels you generate are endless!</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h5">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c5" aria-expanded="false" aria-controls="flush-c5">
                             Where can I use these novels?
                          </button>
                        </h3>
                        <div id="flush-c5" className="accordion-collapse collapse" aria-labelledby="flush-h5" data-bs-parent="#accflush">
                          <div className="accordion-body">
                               <p>You can use our AI driven novels for any use case.</p>
                          </div>
                        </div>
                      </div>

                    </div>
                    
                </div>

                <div className="col-lg-6">
                    <div className="accordion accordion-flush" id="accflush1">

                        <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h1-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c1-1" aria-expanded="false" aria-controls="flush-c1-1">
                            How many novels can I generate?
                          </button>
                        </h3>
                        <div id="flush-c1-1" className="accordion-collapse collapse" aria-labelledby="flush-h1-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                             <p>Free users are capped at 10 short novels a day, and we generally have a character limit, where you can pay for more, or just subscribe monthly</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h2-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c2-1" aria-expanded="false" aria-controls="flush-c2-1">
                            Can I share the novels to social media?
                          </button>
                        </h3>
                        <div id="flush-c2-1" className="accordion-collapse collapse" aria-labelledby="flush-h2-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                              <p>Yes, we generally encourage sharing your AI generated novels to social media such as facebook, tiktok, instagram, pinterest, X etc..</p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h3-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c3-1" aria-expanded="false" aria-controls="flush-c3-1">
                            How fast does Novelfy generate novels?
                          </button>
                        </h3>
                        <div id="flush-c3-1" className="accordion-collapse collapse" aria-labelledby="flush-h3-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                             <p>We use cloud hosting for all of our services which substantially improves our load time, and how fast everything works. </p>
                          </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h4-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c4-1" aria-expanded="false" aria-controls="flush-c4-1">
                            How can I see my past generated novels?
                          </button>
                        </h3>
                        <div id="flush-c4-1" className="accordion-collapse collapse" aria-labelledby="flush-h4-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                                <p>Subscribers have the option to keep their past novels stored on their account. You also have the option to delete any novels. Free users can save up to 5 novels.</p>
                            </div>
                        </div>
                      </div>

                      <div className="accordion-item">
                        <h3 className="accordion-header" id="flush-h5-1">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-c5-1" aria-expanded="false" aria-controls="flush-c5-1">
                            Is there 24/7 customer support?
                          </button>
                        </h3>
                        <div id="flush-c5-1" className="accordion-collapse collapse" aria-labelledby="flush-h5-1" data-bs-parent="#accflush1">
                          <div className="accordion-body">
                                <p>We do not have 24/7 customer support, but you can email us at any time or contact us on twitter for a faster response.</p>
                            </div>
                        </div>
                      </div>

                    </div>
                </div>


            </div>
        </div>
    );
}

export default help;