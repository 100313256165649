import React from 'react';
import { Link } from "react-router-dom";

const server = () => {
    return(
        <div className="container">
            <div className="row align-items-center gx-5">
                <div className="col-lg-6">
                    <img width="1000" height="600" src="./img/misc/server.webp" className="img-fluid mb-sm-30" alt=""/>
                </div>

                <div className="col-lg-6">
                    <div className="subtitle  mb-3">Server locations</div>
                    <h2 className=""><span className="text-gradient">600</span> points of presence. Servers available worldwide for faster connections.</h2>
                    <p className="">Our propeitary technology automatically pulls from a cloud server near you to increase your response time. Our range spans 105 available zones within 33 geographic regions around the world, with annouunced plans for 12 more availability zones and 4 more regions in germany, Malaysia, New Zealand, and Thailand. </p>

                   
                    <ul>
                        <li><Link to="https://aws.amazon.com/about-aws/global-infrastructure/">Learn more</Link></li>
                    </ul>

                </div>
            </div>
        </div>
    );
}

export default server;