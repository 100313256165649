import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const Customerreviews = () => {
  return (
    <>
    <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="subtitle mb20">Customer reviews</div>
                    <h2 className="wow fadeInUp">4.85 out of 5</h2>
                    <div className="spacer-20"></div>
                </div>
            </div>
        </div>
    <Swiper className="smallslider"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={20}
      slidesPerView="auto"
      pagination={{ clickable: true }}
      centeredSlides
      loop
      slideToClickedSlide
      >
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
              <blockquote>
                  <div className="de-rating-ext">
                      <span className="d-stars">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                      </span>
                  </div>
                  <p>&quot;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio eveniet illum libero error, facere omnis repellendus soluta voluptas quia nobis repellat numquam consequatur sed deleniti fuga beatae in quae itaque.
                  </p>
                  <div className="de_testi_by">
                      <img alt="" src="./img/people/1.jpg"/> <span>Michael S.</span>
                  </div>
              </blockquote>
          </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit tenetur assumenda voluptate repudiandae deleniti quidem sint, voluptatum qui ad dicta eius, aliquid impedit! Soluta eaque neque perspiciatis praesentium, asperiores minus.</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/2.jpg"/> <span>Robert L.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem, ipsum dolor sit amet consectetur adipisicing elit. Pariatur totam dolores vero numquam modi placeat, facere consectetur esse reprehenderit corporis quam distinctio optio iste dolor nihil eius hic, doloribus tenetur!</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/3.jpg"/> <span>Jake M.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                  <blockquote>
                      <div className="de-rating-ext">
                          <span className="d-stars">
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                              <i className="fa fa-star"></i>
                          </span>
                      </div>
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi accusantium maiores, error quas natus autem totam numquam perspiciatis distinctio, rem cupiditate. At inventore, natus beatae dolorem exercitationem non quis animi!&quot;</p>
                      <div className="de_testi_by">
                          <img alt="" src="./img/people/4.jpg"/> <span>Alex P.</span>
                      </div>
                  </blockquote>
              </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloremque voluptatem voluptas minima quas dolorem corporis perspiciatis laudantium repellat, architecto modi inventore fugit cupiditate sapiente tempora ducimus facere aliquid temporibus quibusdam!&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/5.jpg"/> <span>Carlos R.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam commodi vero excepturi expedita aliquam. Similique consectetur natus harum nobis inventore, odit maiores architecto nam delectus? Est quas dolores error sapiente.&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/6.jpg"/> <span>Edward B.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi natus eum repellendus maxime esse reprehenderit nam ullam, consequuntur mollitia, quod autem sint, aperiam deleniti corrupti. Repellendus enim sed architecto sint!&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/7.jpg"/> <span>Daniel H.</span>
                    </div>
                </blockquote>
            </div>
        </div> 
      </SwiperSlide>
      <SwiperSlide>
        <div className="swiper-inner">
            <div className="de_testi type-2">
                <blockquote>
                    <div className="de-rating-ext">
                        <span className="d-stars">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                        </span>
                    </div>
                    <p>&quot;Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quibusdam tempora officia at sapiente veniam magnam molestias. Unde voluptas reprehenderit illo ullam ad, quis similique officiis atque sunt veritatis minus!&quot;</p>
                    <div className="de_testi_by">
                        <img alt="" src="./img/people/8.jpg"/> <span>Bryan G.</span>
                    </div>
                </blockquote>
            </div>
        </div>         
      </SwiperSlide>
    </Swiper>
    </>
  );
};
export default Customerreviews;